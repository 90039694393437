.Profile-header{
  border: 1px solid lightgray;
  background-color: #d2e1fb;
  border-radius: 3px;
}
.Pics-div{
  display: flex;
  align-items: center;
}
.img-profile{
  width: 55px;
  border-radius: 50%;
}
.genderIcon{
    width: 16px !important;
    border-radius: 5px;
}
.ProfileIcon{
    width: 20px !important;
}
.Test-Container{
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-bottom: 10px;
  /* background-color: aliceblue; */
}
.result-Textbox{
  outline: none;
  border: 1px solid darkgray;
  width: 100px;
  background: transparent;
  border-radius: 3px;
  text-align: center;
  height: 30px;
}
.ProfileBody{
  padding-left: 0px !important;
  border: 1px solid lightgray;
}
.Result-Savebutton{
  border: 1px solid #2c6bd7;
  outline: none;
  padding: 1px 10px;
  width: 60px;
  font-size: small;
  background-color: transparent;
  color: #2c6bd7;
  border-radius: 3px;
}
.Result-Updatebutton{
  border: 1px solid #2c6bd7;
  outline: none;
  padding: 1px 10px;
  width: 60px;
  font-size: small;
  background-color: transparent;
  color: #2c6bd7;
  border-radius: 3px;
}
.Result-Savebutton:disabled{
  background-color: transparent;
  border-color: lightgray;
  color: lightgray;
}
.Result-Savebutton:disabled:hover{
  background-color: transparent;
  border-color: lightgray;
  color: lightgray;
}
.Result-Updatebutton:hover{
  background-color: #2c6bd7;
  color: white;
}
.Result-Savebutton:hover{
  background-color: #2c6bd7;
  color: white;
}
.ProfileBody{
  padding-left: 0px !important;
  border: 1px solid lightgray;
}
.Presc_header{
  text-align: center !important;
  padding: 5px !important;
  font-family: "-apple-system","BlinkMacSystemFont","Segoe UI","Roboto","Oxygen","Ubuntu","Fira Sans","Droid Sans","Helvetica Neue","sans-serif" !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 1.66666667 !important;
  letter-spacing: 0 !important;
  text-transform: none !important;    
}
.Tbody-column{
  text-align: center !important;
  font-family: "-apple-system","BlinkMacSystemFont","Segoe UI","Roboto","Oxygen","Ubuntu","Fira Sans","Droid Sans","Helvetica Neue","sans-serif" !important;
  font-size: 12px !important;
  line-height: 1.66666667 !important;
  letter-spacing: 0 !important;
  text-transform: none !important;

}
.Profile-table{
  width: 100%;
  border-radius: 5px;
  background-color: aliceblue;
  border-radius: 5px;
 
}
.Comment-button{
  border: 1px solid #13b5f2;
  outline: none;
  padding: 1px 10px;
  width: 90px;
  font-size: small;
  background-color: transparent;
  color: #13b5f2;
  border-radius: 3px;
  margin-left: 3px;
}
.Comment-button:hover{
  background-color: #13b5f2;
  color: white;
}
.Table-Head{
  background-color: lightblue;
  text-align: center;
}
.Table-body{
  text-align: center;
  max-height: 20rem;
  overflow: auto;
  overflow-x: hidden;
}
.T-body-row{
  padding: 3px;
  border-bottom: 1px solid lightgray;
}
.printButton{
  background-color: transparent;
  color: #2c6bd7;
  border-radius: 2px;
  cursor: pointer;
  opacity: 1;
  border: 1px solid #2c6bd7;
  width: 90px;
  height: 30px;
  margin-top: 10px;
  margin-right: 15px;
}
.printButton:hover{
  background-color: #2c6bd7;
  color: white;
}
.printButton:disabled{
  background-color: transparent;
  color: lightgrey;
  cursor: pointer;
  opacity: 1;
  border: 1px solid lightgrey;
}

/* tabs css */
.profile-vertical-parent{
  width: 25%;
  /* border: 1px solid lightgray; */
  border-radius: 3px;
}
.profile-vertical-tabs{
  display: flex;
  flex-direction: column;
  padding: 8px;

}
.profile-Tabs-content{
  width: 75%;
  padding: 4px 4px 10px 4px; 
  /* border: 1px solid lightgray; */
  border-radius: 3px;
}
.profile-tab{
  border-bottom: 1px solid lightgray;
  border-radius: 0px  !important;
  color: gray;
  padding: 3px;
  line-height: 10px;
}.profile-tab:hover{
  color: gray;
}
.profile-tab-active{
  border-radius: 0px !important;
  background: aliceblue;
  border-bottom: 1px solid lightgray;
  color: gray;
  padding: 3px;
  line-height: 10px;
}
.profile-th{
  color: gray !important;
  background-color: #f8f8f8 !important;
  align-items: center;
  text-align: center;
  font-weight: 100 !important;
}
.profile-td{
  align-items: center;
  text-align: center;
  width: 12px;
}
.p-table{
  border: 1px solid lightgray;
}
.profile-gray{
  color: gray !important;
  font-weight: 100 !important;
  cursor: pointer;
  text-decoration: underline;
}
.profile-label{
  padding: 8px 0px 0px 10px;
  color: gray !important;
}

 .phone-icon{
  height: 20px !important;
  width: 20px !important;
}
.details-heading{
    background: aliceblue;
    padding: 8px;
    border-radius: 3px;
    font-size: 10px;
}
.green-background{
  background-color: green;
  color: white;
}

