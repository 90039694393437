html, body, #root {
  height: 100%;
}
.LayoutSidebar{
  height: 100%;
  
}
.LayoutBody{
  width: 100%;
  height: 100%;
  /* background-color: #F2F2F8; */
}