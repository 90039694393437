.DTC1{
    text-align: center !important;
    padding: 5px !important;
    font-family: "-apple-system","BlinkMacSystemFont","Segoe UI","Roboto","Oxygen","Ubuntu","Fira Sans","Droid Sans","Helvetica Neue","sans-serif" !important;
    /* color: #7A869A !important; */
    font-size: 12px !important;
    font-weight: 600 !important;
    line-height: 1.66666667 !important;
    letter-spacing: 0 !important;
    text-transform: none !important;
  }
  .DTC{
    padding: 5px !important;
    text-align: center !important;
    font-family: "-apple-system","BlinkMacSystemFont","Segoe UI","Roboto","Oxygen","Ubuntu","Fira Sans","Droid Sans","Helvetica Neue","sans-serif" !important;
    font-size: 12px !important;
    line-height: 1.66666667 !important;
    letter-spacing: 0 !important;
    text-transform: none !important;
  }
  .D-Box{
    border-radius: 5px;
    padding: 2rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }
 
  .dashboard-boxes{
    background-color: rgb(255, 255, 255);
    margin-bottom: 0px;
    width: 100%;
    padding: 2rem;
    transition: 0.3s;
  }