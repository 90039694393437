/* CSS styles for the search list */
.search_List {
    overflow: auto;
    list-style: none; /* Remove default list styles */
    padding: 0px; /* Remove default padding */
    border-radius: 0px;
    /* border: var(--bs-border-width) solid var(--bs-border-color); */
    max-height: 16rem;
    margin-bottom: 0px;
    }
  
  .search_List li {
    background-color: #ffff;
    padding: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: #e0e0e0;
    margin-top: 4px;
  }
  
  /* Hover effect on list items */
  .search_List li:hover {
    background-color: #e3f2fd; /* Change background color on hover */
  }
  .Cross{
    color: red;
    cursor: pointer;
  }
  .Test_table{
    background-color: #ffff; 
    max-height: 12rem;
    min-height: 12rem;
    overflow: auto;
    padding: 7px;
    font-family: "-apple-system","BlinkMacSystemFont","Segoe UI","Roboto","Oxygen","Ubuntu","Fira Sans","Droid Sans","Helvetica Neue","sans-serif" !important;
    font-size: 12px !important;
  }
  .Table{
    font-size: small !important;
  }
  .ResultInput{
    border: none;
    outline: none;
    border-radius: 5px;
    background-color:rgb(153, 219, 224) !important;
    width: 30%;
  }
  .Discount_Input{
    width: 3rem !important;

  }
  .discount-container {
    width: 7rem;
    height: 2.5em;
    display: flex;
  }
  
  .discount-container select {
    width: 100%;
    padding: 4px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .search-div{
    display: flex;
    border-radius: 0% !important;
    border: 1px solid lightgray;
  }
  .clear-button{
    border: none;
    outline: none;
    background: #e5e5e5;
  }
  .clear-cross{
    color: darkgray;
  }
  .search-input{
    outline: none !important;
    border: none !important;
    display: block;
    width: 100%;
    padding: .335rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border-radius: 0px;
    font-family: "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", "sans-serif" !important;
    font-size: 12px !important;
  }
  .select-All-search-list{
    width: 100%;
    border: none;
    outline: none;
    height: auto;
    background: transparent;
  }
.ReceivedAmmount{
  width: 100%;
  outline: none;
  border: none !important;
}
.DiscountDropdown{
  width: 100%;
  outline: none;
  border: none !important;
}
.search-Test-dev{
  border:1px solid lightgray;
  padding: 0px;
}
.Financial-dev{
  border:1px solid lightgray;
  padding: 0px;
}
.TestList-dev{
  padding: 0px;
  border:1px solid lightgray;
}
.Parent-search-div{
  padding: 4px 10px;
  background-color: #e3f2fd;

}
.Table-Th{
  padding: 10px;
  background-color: #e3f2fd;
}
.finance-item{
  margin-top: 10px;
}
.finance-section-heading{
  padding: 10px;
  text-align: center;
  background-color: #e3f2fd;
}
.table-td{
  padding: 3px 10px;
}
.child-container{
  min-height: 18rem;
}
.f-item-border{
  width: 60px;
  outline: none;
  border: 1px solid lightgray !important;
}

  /* scroll barr for dev */
.search_List::-webkit-scrollbar {
  width: 12px;               
}
.search_List::-webkit-scrollbar-track {
background: rgb(243, 243, 243);   
border-radius: px;    
}
.search_List::-webkit-scrollbar-thumb {
background-color: #e3f2fd;    
border-radius: 0px;       
border: 3px solid #e3f2fd; 
}
/* ---------END------------ */
/* ------------another scrill barr--------- */
.t-body-div::-webkit-scrollbar {
  width: 12px;               
}
.t-body-div::-webkit-scrollbar-track {
background: rgb(243, 243, 243);   
border-radius: px;     
}
.t-body-div::-webkit-scrollbar-thumb {
background-color: #e3f2fd;    
border-radius: 0px;      
border: 3px solid #e3f2fd; 
}
/* ----------END------------- */
.t-body-div{
  max-height: 16rem;
  overflow: auto;
}