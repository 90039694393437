.AddPatient_input{
    padding:8.5px 8px 8px 8px !important;
    width: 200px !important;
    font-size: smaller !important;
    outline: none;
    border-radius: 0px !important;
  }
  .AddPatient_Label{
     font-size: smaller !important;
   }
   .AddPatient_input_DropDown{
    padding:5.5px 6px 6px 8px !important;
    width: 200px !important;
    font-size: smaller !important;
   }
   .child-container-pr{
    padding: 5px 10px 0px 10px;
    width:100% ;
    overflow: hidden;
    border: 1px solid lightgray;
  }
