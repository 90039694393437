
.US-tr{
  background-color: #2c6bd7;  
}
.UM-label{
    padding: 3px 0px 0px 10px;
}
.Action_Button{
  border: 1px solid lightgray;
    padding: 10px;
}