.heading-div{
    padding: 5px 5px 0px;
}
.Activ-tab{
      /* border-bottom: 5px solid #2c6bd7; */
      border-radius: 0px !important;
      color: #2c6bd7;
      background-color: #2c6bd7;
      color: white !important;
}
.tab{
    color: #2c6bd7 !important;
}
.tab-ul{
    border-bottom: 1px solid lightgray;
}