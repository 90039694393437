body{
    background-color: #ffffff;
}

.loginForm{     
    position: relative;
    text-align: center;
    padding: 30px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
    border-radius: 5px;
    top: 5rem;
    border: none;
    width: 320px;
}
.Login_input{
        padding:8.5px 8px 8px 8px !important;
        font-size: smaller !important;
        outline: none;
        border-radius: 0px !important;
        width: 15rem !important;
}
.Submit_Button{
    width: 100%;
    padding: 7px;
    outline: none;
    border: none;
    background: #2c6bd7;
    color: white;
    border-radius: 0px;
    
}
.PicsDiv{
    text-align: center;
    padding-bottom: 1rem;
}
.LoginPics{
    width: 15rem;
    height: 10rem;
}
  .Login_Label{
     font-size: smaller !important;
   }
  
   