.PT-searchbox{
    display: flex !important;
    justify-content: space-between;
}
.PT-search-input{
    width: 50%;
}
.vertical-parent{
    width: 25%;
    border-radius: 3px;
}
.vertical-tabs{
    display: flex ;
    flex-direction: column;
    padding: 8px;

}
.Tabs-content{
    width: 75%;
    padding: 10px 4px 10px 4px;
    border-radius: 3px;
}
.PO-tab{
    border-bottom: 1px solid lightgray;
    border-radius: 0px !important;
    color: gray;
    padding: 3px;
    line-height: 12px;
    font-size: 10px;
}.PO-tab:hover{
    color: gray;
}
.PO-tab-active{
    border-radius: 0px !important;
    background: aliceblue;
    border-bottom: 1px solid lightgray;
    color: gray;
    padding: 3px;
    line-height: 12px;
    font-size: 10px;
}
.POR-th{
    background-color: aliceblue  !important;
    color: black !important;
}
.p-table{
    border: 1px solid lightgray;
}
.gray{
    color: gray !important;
    font-weight: 100 !important;
    cursor: pointer;
    text-decoration: underline;
}
.ORD-label{
    padding: 8px 0px 0px 10px;
}