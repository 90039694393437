.signupForm{     
    position: relative;
    top: 100px;
    align-items: center;
    
}
.SignUp_input{
    padding:8.5px 8px 8px 8px !important;
    width: 200px !important;
    font-size: smaller !important;
}
.SignUp_input_dropdown{
    padding:5.5px 6px 6px 8px !important;
    width: 200px !important;
    font-size: smaller !important;
  }

