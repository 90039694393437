.Container2{
    margin-top: 4rem;
    /* height: 100%; */
}
.Test_card{
    padding: 1rem;
}
.Tes_button{
   color: rgb(7, 209, 224);
}
.title{
    font-size: small;
    text-align: center;
  }
  Divider{
    color: black;
  }
  .TestEntry_input{
    padding:8.5px 8px 8px 8px !important;
    font-size: smaller !important;
    width: 200px !important;
  }
  .input_border ::-moz-focus-outer{
    border: 1px;
  }
  .TestEntry_Label{
     font-size: smaller !important;
   }
   .BorderColor {
    border-color: red; /* Change the border color to red when focused */
  }
  .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
    width: 100% !important;
  }
  .Test_input_DropDown{
    padding:5.5px 6px 6px 8px !important;
    width: 100px !important;
    font-size: smaller !important;
   }